































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import { Environment, EnvironmentUser, User } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import Fields from '@/components/form/Fields.vue'
import { confirm, confirmDelete } from '@/components/dialogs'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    Loading,
    Fields
  },
  apollo: {
    savedUser: {
      query: gql`
        query getEnviromentUser($environmentUserId: ID) {
          savedUser: environmentUser(environmentUserId: $environmentUserId) {
            _id
            email
            profile
            roles
            canAddUsers
            canRemoveUsers
            canEditProfiles
            canManageRoles
            managedRoles
            canViewAllMessages
          }
        }
      `,
      variables() {
        return {
          environmentUserId: this.componentId
        }
      },
      fetchPolicy: 'network-only'
    },
    environment: {
      query: gql`
        query getEnvironmentProfileFields($environmentId: ID) {
          environment(environmentId: $environmentId) {
            _id
            serializedProfileSchema
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class EnvironmentUserEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Prop({ type: Object, required: false }) environmentUser?: EnvironmentUser

  desktopApp = getEnv('VUE_APP_IS_DESKTOP')

  savedUser: EnvironmentUser | null = null
  user: Partial<EnvironmentUser> = {}
  environment: Partial<Environment> = {}

  saving = false
  valid = false

  @Watch('savedUser')
  update(newData: EnvironmentUser) {
    this.$set(this, 'user', _cloneDeep(this.savedUser))
  }

  @Watch('user.email')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  async save() {
    if (!this.user || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation (
            $environmentUserId: ID
            $roles: [ID]
            $profile: JSON
            $canAddUsers: Boolean
            $canRemoveUsers: Boolean
            $canEditProfiles: Boolean
            $canManageRoles: Boolean
            $managedRoles: [ID]
            $canViewAllMessages: Boolean
          ) {
            setEnvironmentUserRoles(
              environmentUserId: $environmentUserId
              roles: $roles
            ) {
              _id
            }
            setEnvironmentUserProfile(
              environmentUserId: $environmentUserId
              profile: $profile
            ) {
              _id
              profile
            }
            setEnvironmentUserPermissions(
              environmentUserId: $environmentUserId
              canAddUsers: $canAddUsers
              canRemoveUsers: $canRemoveUsers
              canEditProfiles: $canEditProfiles
              canManageRoles: $canManageRoles
              managedRoles: $managedRoles
              canViewAllMessages: $canViewAllMessages
            ) {
              _id
              canAddUsers
              canRemoveUsers
              canEditProfiles
              canManageRoles
              managedRoles
              canViewAllMessages
            }
          }
        `,
        // Parameters
        variables: {
          environmentUserId: this.user._id,
          roles: this.user.roles,
          profile: this.user.profile || {},
          canAddUsers: this.user.canAddUsers,
          canRemoveUsers: this.user.canRemoveUsers,
          canManageRoles: this.user.canManageRoles,
          managedRoles: this.user.managedRoles,
          canEditProfiles: this.user.canEditProfiles,
          canViewAllMessages: this.user.canViewAllMessages
        }
      })

      this.savedUser = {
        ...result.data.setEnvironmentUserPermissions,
        profile: result.data.setEnvironmentUserProfile.profile
      }
      this.$emit('save', this.savedUser)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        '¿Seguro que quieres eliminar por completo este usuario del ambiente?'
      ))
    )
      return
    if (!this.user || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($environmentUserId: ID) {
            removeEnvironmentUser(environmentUserId: $environmentUserId)
          }
        `,
        // Parameters
        variables: {
          environmentUserId: this.user._id
        }
      })
      this.$emit('delete', result.data.removeEnvironmentUser)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  get dirty() {
    return !_isEqual(this.user, this.savedUser)
  }

  get loggedUser(): User {
    return this.$store.state.auth.user
  }

  get isAdmin() {
    return (
      this.loggedUser.roles.indexOf('admin') >= 0 ||
      this.loggedUser.roles.indexOf('superAdmin') >= 0
    )
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
